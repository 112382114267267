import React from 'react';
import { CssBaseline, ThemeProvider } from '@material-ui/core';
import { BrowserRouter as Router } from 'react-router-dom';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import { SnackbarProvider } from 'notistack';
import { ApolloProvider } from '@apollo/client';
import { AppFormProvider } from './context/FormContext';

import 'moment/locale/ru';
import 'react-phone-number-input/style.css';

import client from './GraphQL/client';
import Layout from './Layout';

import theme from './assets/theme';
import './utils/i18n';
import { useTranslation } from 'react-i18next';

import { SettingsProvider } from './context/SettingsContext';
import { GoogleOAuthProvider } from '@react-oauth/google';

function App() {
  const { ready } = useTranslation();

  return (
    <ApolloProvider client={client}>
      <GoogleOAuthProvider
        clientId={process.env.REACT_APP_GOOGLE_APP_ID as string}
      >
        <ThemeProvider theme={theme}>
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <SnackbarProvider
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
            >
               <SettingsProvider>
                <AppFormProvider>
                  <CssBaseline />
                  <Router>{ready && <Layout />}</Router>
                </AppFormProvider>
              </SettingsProvider>
            </SnackbarProvider>
          </MuiPickersUtilsProvider>
        </ThemeProvider>
      </GoogleOAuthProvider>
    </ApolloProvider>
  );
}

export default App;
